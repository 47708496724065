import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Logo_icon} from '../imagepath';
import $ from "jquery"

class SidebarNav extends Component {

    componentDidMount() {

    }

    render() {
        if (this.props.isAuthenticated) {
            const exclusionArray = ["login", "register", "forgot-password", "lock-screen", "error-404", "error-500"];
            if (
                exclusionArray.indexOf(this.props.location.pathname.split("/")[1]) >= 0
            ) {
                return "";
            }
            const {location} = this.props
            const pathname = location.pathname.split("/")[1];

            return (
                <>
                    {/* Sidebar */}
                    <div className="sidebar" id="sidebar">
                        <div className="sidebar-logo">
                            <Link to="/">
                                <img src={Logo_icon} className="img-fluid" alt=""/>
                            </Link>
                        </div>
                        <div className="sidebar-inner slimscroll">
                            <div id="sidebar-menu" className="sidebar-menu">
                                <ul>
                                    <li className={`${pathname === "/" || pathname === "" ? "active" : ""}`}>
                                        <Link to="/"><i className="fas fa-columns"/> <span>Dashboard</span></Link>
                                    </li>
                                    <li className={`${pathname === "profile" ? "active" : ""}`}>
                                        <Link to="/profile"><i className="fas fa-columns"/> <span>Profile</span></Link>
                                    </li>
                                    <li className={`${pathname === "stories" ? "active" : ""}`}>
                                        <Link to="/stories"><i className="fas fa-blog"/>
                                            <span> Stories</span></Link>
                                    </li>
                                    <li className={`${pathname === "job" ? "active" : ""}`}>
                                        <Link to="/job"><i className="fas fa-columns"/> <span>Job List</span></Link>
                                    </li>
                                    
                                    <li className={""}>
                                        <a onClick={this.props.logOut}><i className="fas fa-lock"/>
                                            <span> Log Out</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Sidebar */}
                </>
            );
        } else {
            return null
        }
    }
}

export default withRouter(SidebarNav);
