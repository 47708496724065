import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Loadable from 'react-loadable';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modal-slider/lib/main.css';
import Notifications, {notify} from 'react-notify-toast';
import async from 'async';
import _ from 'lodash';
import LoadingComponent from './components/loader';
import Header from "./components/header/index";
import Sidebarnav from "./components/sidebar";
import callApi from './util/apiCaller';
import Error404 from "./components/error/error-404";

import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import 'antd/dist/antd.css';
import "./assets/plugins/bootstrap/css/bootstrap.min.css"
import "./assets/plugins/fontawesome/css/fontawesome.min.css"
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/animate.min.css";
import "./assets/plugins/jvectormap/jquery-jvectormap-2.0.3.css";
import "./assets/css/admin.css";


const AsyncLoginPage = Loadable({
    loader: () => import('./modules/App/LoginPage/LoginPage'),
    loading: LoadingComponent,
});
const AsyncProfile = Loadable({
    loader: () => import('./modules/Profile/Profile/Profile'),
    loading: LoadingComponent,
});
const AsyncJobList = Loadable({
    loader: () => import('./modules/Content/Jobs/JobList'),
    loading: LoadingComponent,
});

const AsyncDashboard = Loadable({
    loader: () => import('./modules/App/Dashboard/Dashboard'),
    loading: LoadingComponent,
});
const AsyncBlog = Loadable({
    loader: () => import('./modules/Content/Stories/BlogList'),
    loading: LoadingComponent,
});



const PrivateRoute = ({component: Component, isAuthenticated: isAuthenticated, userPermissions, shouldHavePermissions, ...rest}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {_refurl: props.location}
            }}/>
    )}/>
);


class App extends Component {
    constructor() {
        super();
        this.state = {
            isMounted: false,
            isLoading: false,
            userid: '',
            agentid: '',
            user: {},
            agent: {},
            isAuthenticated: false,
            token: '',
            notification: {
                message: '',
                link: '',
                timeout: 3,
                msgId: '',
            },
            cities: [],
            userPermissions: [],
            isLoggingIn: false,
            links: [],
            faveLinks: [],
            userprefId: '',
            breadcrumbs: [{
                link: '/profile',
                name: 'Home',
                isActive: false
            }],
        };
        this.isAuthenticated = false;
    }

    componentDidMount() {
        let token = window.localStorage.getItem('token');
        if (token && token !== '') {
            this.setState({
                isLoading: true
            });
            this.verifyToken(token, (err, user) => {
                console.log(err, user);
                if (err) {
                    window.localStorage.removeItem('token');
                } else {
                    this.isAuthenticated = true;
                    let model = {
                        user: user
                    };
                    if (!_.isEmpty(model.user)) {
                        this.setState({
                            isMounted: true,
                            isLoading: false,
                            isAuthenticated: true,
                            userid: model.user._id,
                            token: token,
                            user: model.user,
                        });
                    }
                }
            });
        }
    }

    updateUser = (user) => {
        this.setState({user: user})
    }

    updateAgent = (agent) => {
        callApi(`v2/agents/${this.state.agentid}/update`, 'POST', {
            agent: agent
        }).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    agent: res.data.agent
                });
                this.showMessage('Updated', 'success');
            }
        });
    };

    fetchAgent = (id, next) => {
        callApi(`profile/user/${id}/info`, 'GET').then(res => {
            return next(null, res.data.user);
        });
    };

    loginUser = (creds) => {
        this.setState({
            isLoggingIn: true
        });
        callApi(`auth/login`, 'post', {
            creds: creds
        }).then(res => {
            this.setState({
                isLoggingIn: false
            });
            if (res.status === 'Success') {
                if ( res.data.isUserFound) {
                    window.localStorage.setItem('token', res.data.token);
                    window.localStorage.setItem('userId', res.data.user._id);
                    window.location = "/";
                }
            } else {
                // this.showMessage(res.data.message, 'error');
            }
        });
    };

    verifyToken = (token, next) => {
        const userId = window.localStorage.getItem('userId');
        if (userId) {
            callApi(`auth/profile/${userId}/info`, 'get').then(res => {
                if (res.status === 'Success') {
                    let user = res.data.user;
                    return next(null, user);
                } else {
                    return next('Unauthorized');
                }
            });
        }
    };

    logoutUser = () => {
        window.localStorage.removeItem('token');
        this.isAuthenticated = false;
        window.location = '/';
    };

    showMessage = (msg, msgtype) => {
        notify.show(msg, msgtype, 3000);
    };

    notifyToAgent = (msg) => {
        let text = msg.text;
        let options = {};
        if (msg.isTimedNotification) {
            options.autoClose = msg.timer;
        } else {
            options.autoClose = false;
        }
        if (msg.notification_type === 'success') {
            toast.success(text, options);
        } else {
            toast.info(text, options);
        }
    };

    clearAgentNotifications = () => {
        toast.dismiss();
    };

    fetchAccessList = (id, next) => {
        callApi(`v2/venueaccesses/${id}/populated`, 'get').then(res => {
            if (res.status === 'Success') {
                let access = res.data.venueaccess;
                return next(null, access);
            } else {
                return next('Unauthorized');
            }
        });
    };

    notifyToAdmin = (message, link, timeout) => {
        let notification = this.state.notification;
        notification.message = message;
        notification.link = link ? link : '';
        notification.timeout = timeout;
        notification.msgId = this.generateMessageId(8);
        this.setState({
            notification: notification,
        });
    };

    rand = (min, max) => {
        let random = Math.random();
        return Math.floor(random * (max - min) + min);
    };

    generateMessageId = (length) => {
        let loopLength = length || 8;
        let allowsChars = '0123456789';
        let password = '';
        for (let index = 0; index < loopLength; ++index) {
            let charIndex = this.rand(0, allowsChars.length - 1);
            password += allowsChars[charIndex];
        }
        return password;
    };

    render() {
        console.log("App", this.state);
        return (
            <div>
                <Router>
                    <div className="App">
                        <Route render={(props) => <Header {...props} />}
                               isAuthenticated={this.state.isAuthenticated}
                        />
                        <Route render={(props) => <Sidebarnav {...props}
                                                              logOut={this.logoutUser}
                                                              isAuthenticated={this.state.isAuthenticated}
                        />}/>
                        <div id="page-content-wrapper">
                            <div id="wrapper">
                                <div className="static-content-wrapper">
                                    <div className="static-content">
                                        <div className="page-content">

                                            <div className="container-fluid"
                                                 style={{minHeight: '550px'}}>
                                                <Switch>
                                                    <Route exact path="/login"
                                                           render={props =>
                                                               <AsyncLoginPage {...props}
                                                                               isLoggingIn={this.state.isLoggingIn}
                                                                               isAuthenticated={this.state.isAuthenticated}
                                                                               user={this.state.user}
                                                                               loginUser={this.loginUser}/>}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/profile"
                                                        component={props =>
                                                            <AsyncProfile
                                                                {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                updateUser={this.updateUser}
                                                            />}/>
                                                         <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/job"
                                                        component={props =>
                                                            <AsyncJobList {...props}
                                                                               isLoggingIn={this.state.isLoggingIn}
                                                                               isAuthenticated={this.state.isAuthenticated}
                                                                               user={this.state.user}
                                                                               loginUser={this.loginUser}/>
                                                        }/>


                                                         <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/stories"
                                                        component={props =>
                                                            <AsyncBlog {...props}
                                                                            isLoggingIn={this.state.isLoggingIn}
                                                                            isAuthenticated={this.state.isAuthenticated}
                                                                            user={this.state.user}
                                                                            loginUser={this.loginUser}/>
                                                        }/>

                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/"
                                                        component={props =>
                                                            <AsyncDashboard {...props}
                                                                            isLoggingIn={this.state.isLoggingIn}
                                                                            isAuthenticated={this.state.isAuthenticated}
                                                                            user={this.state.user}
                                                                            loginUser={this.loginUser}/>
                                                        }/>
                                                    <Route
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        component={Error404}
                                                    />
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
                <ToastContainer
                    newestOnTop={true}
                />
                <Notifications options={{zIndex: 10000}}/>
            </div>
        )
    }
}

export default App;
